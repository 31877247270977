.contactStyle {
  p {
    color: #bf1650;
  }
  
  p::before {
    display: inline;
    content: "⚠ ";
  }
}
  