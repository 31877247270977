.child-list {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    /* Adjust duration and timing function as needed */
}

.child-list.expanded {
    max-height: max-content;
    transition: max-height 0.5s ease;
    /* Adjust the maximum height as needed */
}

.sidebar-transition{
    transition: width 0.2s ease;
}