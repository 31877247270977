.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999;
    animation: fadeIn 0.3s ease; /* Fade-in effect */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(211, 211, 211, 0.2));
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  