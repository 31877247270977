.container {
    display: flex;
    padding: 10px 20px;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .formControl {
    margin-right: 20px;
    flex-direction: row;
    align-items:center;
    gap: 10px;
    width: 30%;
  }
  
  .label {
    font-weight: bold;
  }
  
  .select {
    width: 150px;
  }
  
  .dateDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .dateText {
    margin: 5px 0;
  }
  