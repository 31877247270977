 
/* ListGroup.module.css */
.list-group {
    padding: 0;
  }
  
  .list-group-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .list-group-item:hover {
    background-color: #f0f0f0; /* Change to your desired hover background color */
  }
  
  .icon {
    font-size: 1.25rem;
    margin-right: 10px;
  }
  
  /* Add more styles as needed */
