#root {
    height: auto !important;
    max-height: 100%;
}

.channel-item svg{
    width: 100%;
    margin: auto;
    padding: 3px 0px 0px 0px;
}

.search-datepicker input {
    padding: 13.5px 14px;
}